'use strict'

const _ = require('lodash')
const stCssPath = ['style', 'properties', '$st-css']

// Hashing stylable styles, this prevents them from being equal. When styles are equal they optimize to one of them
// Which makes components have the same style.
const hashCode = s => s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
}, 0).toString(16)

const componentsToRemove = (pageId, viewMode) => {
    if (viewMode === 'desktop') {
        return 'mobileComponents'
    }
    return pageId === 'masterPage' ? 'children' : 'components'
}

module.exports = (pageJson, viewMode) => {
    const pageId = pageJson.structure.id

    if (pageJson.structure.mobileComponents) {
        _.unset(pageJson.structure, componentsToRemove(pageId, viewMode))
    }

    // Remove stylable css
    _.forEach(_.get(pageJson, ['data', 'theme_data']), item => {
        if (_.has(item, stCssPath)) {
            _.set(item, stCssPath, hashCode(_.get(item, stCssPath)))
        }
    })
}
