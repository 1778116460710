'use strict'

const _ = require('lodash')
const getMeshResults = require('../gen/mesh')
const functionLibrary = require('../functionLibrary/functions')
const getFlattenStructure = require('../utils/flattenStructure')
const {ViewerJsonMeshCalculationError} = require('../errors')

module.exports = ({pageJson, viewMode, masterPageJson, isIE, metricsReporter}) => {
    const isMobile = viewMode === 'mobile'
    try {
        const pageId = pageJson.structure.id
        const flatPage = {
            ...pageJson,
            structure: getFlattenStructure(pageJson.structure, pageId, isMobile)
        }
        const {meshResults} = getMeshResults({
            flatPage,
            isMobileDevice: isMobile,
            isMobileView: isMobile,
            masterPageData: masterPageJson.data,
            isIE
        }, functionLibrary)
        _.set(pageJson, 'meshResults', meshResults)
    } catch (e) {
        if (metricsReporter) {
            metricsReporter.reportError(new ViewerJsonMeshCalculationError(e))
        }
    }
}
