'use strict'
const _ = require('lodash')
const {ViewerJsonInvalidViewModeParamError} = require('./errors')
const setMeshResults = require('./mesh/mesh')
const mergeStyles = require('./utils/mergeStyles')
const removeComponentsByViewMode = require('./viewMode/viewMode')

const VIEW_MODES = ['desktop', 'mobile']

module.exports = context => ({
    execute: ({page, pages, pageJson: optionalPageJson, viewMode, shouldCalcMeshInServer, isIE, shouldMergeStyles}) => {
        if (!VIEW_MODES.includes(viewMode)) {
            throw new ViewerJsonInvalidViewModeParamError(viewMode)
        }

        const metricsReporter = _.get(context, 'metricsReporter')

        let pagePromise
        let masterPagePromise
        const shouldCalcMesh = shouldCalcMeshInServer === 'true' || shouldCalcMeshInServer === true
        
        if (shouldCalcMesh && pages) {
            const pagesIds = Object.keys(pages)
            if (pagesIds.length === 1) {
                pagePromise = pages.masterPage
            } else {
                pagePromise = pages[pagesIds.filter(pageId => pageId !== 'masterPage')[0]]
            }
            masterPagePromise = pages.masterPage
        } else {
            pagePromise = optionalPageJson ? Promise.resolve(optionalPageJson) : page
        }


        return pagePromise.then(async pageJson => {
            _.set(pageJson, 'metaData', {})
            removeComponentsByViewMode(pageJson, viewMode)

            if (shouldCalcMesh) {
                const masterPageJson = await masterPagePromise
                metricsReporter.runAndReport(() => {
                    setMeshResults({pageJson, viewMode, masterPageJson, isIE, metricsReporter})
                }, 'view-mode-json-mesh-calculation')
            }

            if (shouldMergeStyles) {
                mergeStyles(pageJson, viewMode)
            }

            return pageJson
        })
    }
})
